<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step step="1" :complete="e1 > 1">Daftar</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="2" :complete="e1 > 2"
        >Lengkapi Profil Peserta</v-stepper-step
      >
      <v-divider></v-divider>
      <v-stepper-step step="3" :complete="e1 > 3"
        >Asal Pendidikan</v-stepper-step
      >
      <v-divider></v-divider>
      <v-stepper-step step="4" :complete="e1 > 4"
        >Peminatan Program Studi</v-stepper-step
      >
      <v-divider></v-divider>
      <v-stepper-step step="5" :complete="e1 > 5">Upload Berkas</v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <daftars />
        <v-btn
          class="mt-12"
          color="primary"
          @click="e1 = 2"
          v-if="getPstByid != undefined"
          v-show="getPstByid.status_daftar === '1'"
        >
          Continue
        </v-btn>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-card class="overflow-hidden" dark>
          <v-toolbar flat>
            <v-icon>mdi-account</v-icon>
            <v-toolbar-title class="font-weight-light"
              >Profile Peserta</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn fab small @click="isEditing = !isEditing">
              <v-icon v-if="isEditing">mdi-close</v-icon>
              <v-icon v-else>mdi-pencil</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form v-model="valid">
            <v-card-text v-if="getPstByid != undefined">
              <v-row align="center" justify="center">
                <v-col class="d-flex" cols="12" sm="6">
                  <v-text-field
                    :disabled="!isEditing"
                    color="blue"
                    label="Nik"
                    :rules="[rules.required, rules.min, rules.counter]"
                    hint="At least 16 characters"
                    counter
                    maxlength="16"
                    v-model="getPstByid.nik"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-text-field
                    :disabled="!isEditing"
                    color="blue"
                    label="Nama Lengkap"
                    v-model="getPstByid.nm_pst"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-text-field
                    :disabled="!isEditing"
                    color="blue"
                    label="Tempat Lahir"
                    :rules="[rules.required]"
                    v-model="getPstByid.tempat_lahir"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <!-- <v-text-field
                  :disabled="!isEditing"
                  color="blue"
                  label="Tanggal Lahir"
                  v-model="getPstByid.tanggal_lahir"
                ></v-text-field> -->
                  <v-menu
                    ref="tglPst"
                    v-model="tglPst"
                    :close-on-content-click="false"
                    :return-value.sync="getPstByid.tanggal_lahir"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="!isEditing"
                        v-model="getPstByid.tanggal_lahir"
                        label="Tanggal Lahir"
                        prepend-icon="fas fa-calendar-alt"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="getPstByid.tanggal_lahir"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="tglPst = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.tglPst.save(getPstByid.tanggal_lahir)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    :disabled="!isEditing"
                    :items="agama"
                    :filter="customFilter"
                    item-value="id_agama"
                    color="blue"
                    item-text="name"
                    label="Agama"
                    :rules="[rules.required]"
                    v-model="getPstByid.agama"
                  ></v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    :disabled="!isEditing"
                    :items="jenkel"
                    :filter="customFilter"
                    item-value="abbr"
                    color="blue"
                    item-text="name"
                    label="Jenis Kelamin"
                    :rules="[rules.required]"
                    v-model="getPstByid.jenis_kelamin"
                  ></v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-text-field
                    :disabled="!isEditing"
                    color="blue"
                    label="Pekerjaan"
                    v-model="getPstByid.pekerjaan"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-text-field
                    :disabled="!isEditing"
                    color="blue"
                    label="No Hp"
                    v-model="getPstByid.no_telp"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12">
                  <v-text-field
                    :disabled="!isEditing"
                    color="blue"
                    label="Alamat"
                    :rules="[rules.required]"
                    v-model="getPstByid.alamat"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!isEditing || !valid"
              color="success"
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
          <v-snackbar v-model="hasSaved" :timeout="2000" absolute bottom left>
            Your profile has been updated
          </v-snackbar>
        </v-card>
        <v-btn
          class="mt-12"
          color="primary"
          @click="e1 = 3"
          v-if="getPstByid != undefined"
          v-show="getPstByid.status_biodata === '1'"
        >
          Continue
        </v-btn>
      </v-stepper-content>
      <v-stepper-content step="3">
        <v-card color="red lighten-2" dark v-if="getPstByid != undefined">
          <v-toolbar class="red lighten-3" flat>
            <v-icon>mdi-account</v-icon>
            <v-card-title> Asal Pendidikan Peserta </v-card-title>
            <v-spacer></v-spacer>
            <v-btn fab small @click="isEditing1 = !isEditing1">
              <v-icon v-if="isEditing1">mdi-close</v-icon>
              <v-icon v-else>mdi-pencil</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form v-model="valid2">
            <v-card-text>
              <v-divider></v-divider>
              <v-row align="center" justify="center">
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                    :disabled="!isEditing1"
                    :items="asalPendidikan"
                    item-text="name"
                    item-value="id"
                    label="Janis Daftar"
                    v-model="getPstByid.jenis_daftar"
                    @change="check"
                    :rules="[rules.required]"
                  >
                    >
                  </v-select>
                </v-col>
                <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
                  v-show="baru === '' || 'P' || 'B' || 'A' || 'X'"
                >
                  <v-text-field
                    :disabled="!isEditing1"
                    color="blue"
                    :rules="[rules.required]"
                    :label="label(getPstByid.jenis_daftar)"
                    v-model="getPstByid.nisn_nim"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-text-field
                    :disabled="!isEditing1"
                    color="blue"
                    :rules="[rules.required]"
                    :label="label2(getPstByid.jenis_daftar)"
                    v-model="getPstByid.nm_sekolah_pt"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-text-field
                    :disabled="!isEditing1"
                    color="blue"
                    :label="label3(getPstByid.jenis_daftar)"
                    v-model="getPstByid.alamat_sekolah_pt"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
                  v-if="getPstByid.jenis_daftar === 'A'"
                  v-show="baru === 'A'"
                >
                  <v-select
                    :disabled="!isEditing1"
                    :items="jenjang"
                    label="Jenjang Pendidikan"
                    v-model="getPstByid.jenjang_pendidikan"
                    :rules="[rules.required]"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="grey darken-3"
              :disabled="!isEditing1 || !valid"
              @click="seveData"
            >
              Save Data
              <v-icon right> mdi-check-outline </v-icon>
            </v-btn>
          </v-card-actions>
          <v-snackbar v-model="hasSaved1" :timeout="2000" absolute bottom left>
            Your profile has been updated
          </v-snackbar>
        </v-card>
        <v-btn
          class="mt-12"
          color="primary"
          @click="e1 = 4"
          v-if="getPstByid != undefined"
          v-show="getPstByid.status_pendidikan === '1'"
        >
          Continue
        </v-btn>
      </v-stepper-content>
      <v-stepper-content step="4">
        <v-card class="overflow-hidden" color="purple lighten-1" dark>
          <v-toolbar flat color="purple">
            <v-icon>mdi-account</v-icon>
            <v-toolbar-title class="font-weight-light"
              >Profile Peminatan Program Studi</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn
              color="purple darken-3"
              fab
              small
              @click="isEditing2 = !isEditing2"
            >
              <v-icon v-if="isEditing2">mdi-close</v-icon>
              <v-icon v-else>mdi-pencil</v-icon>
            </v-btn>
          </v-toolbar>
          <v-form v-model="valid3">
            <v-card-text v-if="getPstByid != undefined">
              <v-row align="center" justify="center">
                <v-col class="d-flex" cols="12" sm="12">
                  <v-autocomplete
                    :disabled="!isEditing2"
                    :items="kelas"
                    :filter="customFilter"
                    color="white"
                    item-text="name"
                    item-value="id"
                    label="Kelas"
                    v-model="getPstByid.kelas"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    :disabled="!isEditing2"
                    :items="prodies"
                    persistent-hint
                    color="white"
                    item-text="nama_program_studi"
                    item-value="kode_program_studi"
                    label="Pilihan 1"
                    v-model="getPstByid.pilihan_1"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    :disabled="!isEditing2"
                    :items="prodies"
                    color="white"
                    item-text="nama_program_studi"
                    item-value="kode_program_studi"
                    label="Pilihan 2"
                    v-model="getPstByid.pilihan_2"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!valid3"
              color="success"
              @click="sevePeminatan"
              ref="file"
            >
              Save
            </v-btn>
          </v-card-actions>
          <v-snackbar v-model="hasSaved2" :timeout="2000" absolute bottom left>
            Your profile has been updated
          </v-snackbar>
        </v-card>
        <v-btn
          class="mt-12"
          color="primary"
          @click="e1 = 5"
          v-if="getPstByid != undefined"
          v-show="getPstByid.status_peminatan === '1'"
        >
          Continue
        </v-btn>
      </v-stepper-content>
      <v-stepper-content step="5">
        <v-row v-if="getPstByid != undefined">
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card class="mx-auto" max-width="400">
              <v-img
                class="red--text align-end"
                height="200px"
                :src="getPstByid.foto_upload"
              >
                <v-card-title
                  class="align-center"
                  v-if="getPstByid.foto_upload === null"
                  >Upload Foto 3x4</v-card-title
                >
                <v-card-title>{{ getPstByid.last_update_foto }}</v-card-title>
              </v-img>
              <v-card-text class="text--primary">
                <input
                  type="file"
                  id="fileFoto"
                  ref="file"
                  @change="selectFile"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" @click="upload()"> Upload Poto </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card class="mx-auto" max-width="400">
              <v-img
                class="red--text align-end"
                height="200px"
                :src="getPstByid.ijazah_upload"
              >
                <v-card-title
                  class="align-center"
                  v-if="getPstByid.ijazah_upload === null"
                  >Upload Ijazah</v-card-title
                >
                <v-card-title>
                  {{ getPstByid.last_update_ijazah }}</v-card-title
                >
              </v-img>

              <v-card-text class="text--primary">
                <input
                  type="file"
                  id="fileIzajah"
                  ref="file"
                  @change="selectIjazah"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" @click="uploadIjazah()">
                  Upload Ijazah
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card class="mx-auto" max-width="400">
              <v-img
                class="red--text align-end"
                height="200px"
                :src="getPstByid.bukti_bayar_upload"
              >
                <v-card-title
                  class="align-center"
                  v-if="getPstByid.bukti_bayar_upload === null"
                  >Upload Kwitansi Pembayaran</v-card-title
                >
                <v-card-title>
                  {{ getPstByid.last_update_bukti_bayar }}</v-card-title
                >
              </v-img>

              <v-card-text class="text--primary">
                <input
                  type="file"
                  id="fileBukti"
                  ref="file"
                  @change="selectKwitansi"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" @click="uploadKwitansi()">
                  Upload Kwitansi
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card class="mx-auto" max-width="400">
              <v-img
                class="red--text align-end"
                height="200px"
                :src="getPstByid.kk_upload"
              >
                <v-card-title
                  class="align-center"
                  v-if="getPstByid.kk_upload === null"
                  >Upload Kartu Keluarga</v-card-title
                >
                <v-card-title> {{ getPstByid.last_update_kk }}</v-card-title>
              </v-img>

              <v-card-text class="text--primary">
                <input type="file" id="fileKk" ref="file" @change="selectKk" />
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" @click="uploadKk()"> Upload KK </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card class="mx-auto" max-width="400">
              <v-img
                class="red--text align-end"
                height="200px"
                :src="getPstByid.ktp_upload"
              >
                <v-card-title
                  class="align-center"
                  v-if="getPstByid.ktp_upload === null"
                  >Upload KTP Warna</v-card-title
                >
                <v-card-title> {{ getPstByid.last_update_ktp }}</v-card-title>
              </v-img>

              <v-card-text class="text--primary">
                <input
                  type="file"
                  id="fileKtp"
                  ref="file"
                  @change="selectKtp"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" @click="uploadKtp()"> Upload KTP </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card
              class="mx-auto"
              max-width="400"
              v-if="getPstByid.jenis_daftar !== 'B' && 'X'"
            >
              <v-img
                class="red--text align-end"
                height="200px"
                :src="getPstByid.nilai_upload"
              >
                <v-card-title
                  class="align-center"
                  v-if="getPstByid.nilai_upload === null"
                >
                  Upload Trankrip Nilai</v-card-title
                >
                <v-card-title> {{ getPstByid.last_update_nilai }}</v-card-title>
              </v-img>

              <v-card-text class="text--primary">
                <input
                  type="file"
                  id="transkrip"
                  ref="file"
                  @change="selectTranskrip"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" @click="uploadTranskrip()">
                  Upload Transkrip Nilai
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-card
              class="mx-auto"
              max-width="400"
              v-show="getPstByid.jenis_daftar !== 'B' && 'X'"
            >
              <v-img
                class="red--text align-end"
                height="200px"
                :src="getPstByid.surat_pindah_upload"
              >
                <v-card-title
                  class="align-center"
                  v-if="getPstByid.surat_pindah_upload === null"
                >
                  Upload Surat Pindah</v-card-title
                >
                <v-card-title>
                  {{ getPstByid.last_update_surat_pindah }}</v-card-title
                >
              </v-img>

              <v-card-text class="text--primary">
                <input
                  type="file"
                  id="surat_pindah"
                  ref="file"
                  @change="selectSuratPindah"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" @click="uploadSuratPindah()">
                  Upload Surat Pindah
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-btn class="mt-12" color="primary" @click="e1 = 1"> Continue </v-btn>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import daftars from "../views/DashboardUser.vue";
export default {
  data() {
    return {
      baru: null,
      valid: false,
      valid2: false,
      valid3: false,
      tglPst: false,
      e1: 1,
      hasSaved: false,
      hasSaved1: false,
      hasSaved2: false,
      isEditing1: null,
      isEditing: null,
      isEditing2: null,
      search: null,
      file: null,
      fileIjazah: null,
      fileKwitansi: null,
      fileKk: null,
      fileKtp: null,
      fileTranskrip: null,
      fileSuratPindah: null,
      jenkel: [
        { name: "laki-laki", abbr: "L", id: 1 },
        { name: "Perempuan", abbr: "P", id: 2 },
      ],
      agama: [
        { name: "Islam", id_agama: "1" },
        { name: "Katolik", id_agama: "3" },
        { name: "Protestan", id_agama: "2" },
        { name: "Hindu", id_agama: "4" },
        { name: "Budha", id_agama: "5" },
        { name: "LainNya", id_agama: "99" },
      ],
      jenjang: ["D1", "D2", "D3", "D4"],
      kelas: [
        { name: "Khusus", id: "K" },
        { name: "Reguler", id: "R" },
      ],
      asalPendidikan: [
        { name: "Mahasiswa Baru", id: "B" },
        { name: "Mahasiswa Pindahan", id: "P" },
        { name: "Mahasiswa Alih Jenjang", id: "A" },
        { name: "Mahasiswa Pasca Sarjana", id: "X" },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) => (value && value.length >= 16) || "Min 16 characters",
        counter: (value) =>
          (value && value.length <= 16) || "Max 16 characters",
      },
    };
  },
  components: {
    daftars,
  },
  computed: {
    ...mapGetters("auth", ["userInfo"]),
    ...mapGetters("daftar", ["getPstByid"]),
    ...mapGetters("reg", ["prodi"]),
    prodies() {
      return Object.keys(this.prodi).map((k) => {
        let obj = this.prodi[k];
        return obj;
      });
    },
  },
  created() {
    // this.bio = Object.assign({}, this.getPstByid);
    // this.asal = Object.assign({}, this.getPstByid);
    // this.peminatan = Object.assign({}, this.getPstByid);
    this.$store.dispatch("daftar/loadpst", this.userInfo.id);
    this.loadProdi();
  },
  mounted() {
    //this.$store.dispatch("daftar/loadpst", this.userInfo.id);
  },
  methods: {
    ...mapActions("reg", ["loadProdi"]),
    customFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    save() {
      this.isEditing = !this.isEditing;
      this.hasSaved = true;
      this.$store.dispatch("daftar/updateProfil", this.getPstByid).then(() => {
        this.$store.dispatch("daftar/loadpst", this.userInfo.id);
      });
    },
    seveData() {
      this.isEditing1 = !this.isEditing1;
      this.hasSaved1 = true;
      this.$store
        .dispatch("daftar/updateAsalPendidikan", this.getPstByid)
        .then(() => {
          this.$store.dispatch("daftar/loadpst", this.userInfo.id);
        });
    },
    sevePeminatan() {
      this.isEditing2 = !this.isEditing2;
      this.hasSaved2 = true;
      this.$store
        .dispatch("daftar/updatePeminatan", this.getPstByid)
        .then(() => {
          this.$store.dispatch("daftar/loadpst", this.userInfo.id);
        });
    },
    selectFile(e) {
      this.file = e.target.files[0];
    },
    selectIjazah(e) {
      this.fileIjazah = e.target.files[0];
    },
    selectKwitansi(e) {
      this.fileKwitansi = e.target.files[0];
    },
    selectKk(e) {
      this.fileKk = e.target.files[0];
    },
    selectKtp(e) {
      this.fileKtp = e.target.files[0];
    },
    selectTranskrip(e) {
      this.fileTranskrip = e.target.files[0];
    },
    selectSuratPindah(e) {
      this.fileSuratPindah = e.target.files[0];
    },
    upload() {
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("id_pst", this.userInfo.id);
      // for (let pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }
      this.$store.dispatch("daftar/updatePoto", formData).then(() => {
        this.$store.dispatch("daftar/loadpst", this.userInfo.id);
      });
    },
    uploadIjazah() {
      let formIjazah = new FormData();
      formIjazah.append("file", this.fileIjazah);
      formIjazah.append("id_pst", this.userInfo.id);
      this.$store.dispatch("daftar/updateIjazah", formIjazah).then(() => {
        this.$store.dispatch("daftar/loadpst", this.userInfo.id);
      });
    },
    uploadKwitansi() {
      let formKwitansi = new FormData();
      formKwitansi.append("file", this.fileKwitansi);
      formKwitansi.append("id_pst", this.userInfo.id);
      this.$store.dispatch("daftar/uploadKwitansi", formKwitansi).then(() => {
        this.$store.dispatch("daftar/loadpst", this.userInfo.id);
      });
    },
    uploadKk() {
      let formKk = new FormData();
      formKk.append("file", this.fileKk);
      formKk.append("id_pst", this.userInfo.id);
      this.$store.dispatch("daftar/uploadKk", formKk).then(() => {
        this.$store.dispatch("daftar/loadpst", this.userInfo.id);
      });
    },
    uploadKtp() {
      let formKtp = new FormData();
      formKtp.append("file", this.fileKtp);
      formKtp.append("id_pst", this.userInfo.id);
      this.$store.dispatch("daftar/uploadKtp", formKtp).then(() => {
        this.$store.dispatch("daftar/loadpst", this.userInfo.id);
      });
    },

    uploadTranskrip() {
      let formTr = new FormData();
      formTr.append("file", this.fileTranskrip);
      formTr.append("id_pst", this.userInfo.id);
      this.$store.dispatch("daftar/uploadTranskrip", formTr).then(() => {
        this.$store.dispatch("daftar/loadpst", this.userInfo.id);
      });
    },
    uploadSuratPindah() {
      let formSp = new FormData();
      formSp.append("file", this.fileSuratPindah);
      formSp.append("id_pst", this.userInfo.id);
      this.$store.dispatch("daftar/uploadSuratPindah", formSp).then(() => {
        this.$store.dispatch("daftar/loadpst", this.userInfo.id);
      });
    },
    label(e) {
      if (e === "B") return "NISN";
      else if (e === "P") return "NIM";
      else if (e === "A") return "NIM";
      else if (e === "X") return "NIM";
      else return "";
    },
    label2(e) {
      if (e === "B") return "Asal Sekolah";
      else if (e === "P") return "Asal Perguruan Tinggi";
      else if (e === "A") return "Asal Perguruan Tinggi";
      else if (e === "X") return "Asal Perguruan Tinggi";
      else return "";
    },
    label3(e) {
      if (e === "B") return "Alamat Sekolah";
      else if (e === "P") return "Alamat Perguruan Tinggi";
      else if (e === "A") return "Alamat Perguruan Tinggi";
      else if (e === "X") return "Alamat Perguruan Tinggi";
      else return "";
    },
    check(e) {
      this.baru = e;
    },
  },
};
</script>
