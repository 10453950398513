<template lang="html">
  <div>
    <v-row v-if="getPstByid !== null" v-show="getPstByid.status_daftar !== '1'">
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="jadwal in allJadwal"
        :key="jadwal.id"
      >
        <v-card class="mx-auto mb-12" max-width="344" shaped elevation="15">
          <v-card-text>
            <div>Pendaftaran SPMB</div>
            <p class="display-1 text--primary">
              {{ jadwal.nm_jadwal }}
            </p>
            <p>adjective</p>
            <v-divider class="mx-4"></v-divider>
            <v-chip-group
              active-class="deep-purple accent-4 white--text"
              column
            >
              <v-chip>{{ jadwal.tanggal_mulai }}</v-chip>
              <v-btn icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-chip>{{ jadwal.tanggal_akhir }}</v-chip>
            </v-chip-group>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="deep-purple accent-4"
              v-if="jadwal.is_aktiv === '1'"
              @click="daftar(jadwal)"
            >
              Dafatar disini
            </v-btn>
            <v-btn text color="deep-purple accent-4" v-else>
              jadwal Blm Dibuka
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="12">
        <v-alert
          outlined
          type="success"
          text
          v-if="getPstByid != undefined"
          v-show="getPstByid.status_daftar === '1'"
        >
          Berhasil Mendaftar, Silahkan Lanjut Ketahap Berikutnya !!
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-alert
          outlined
          color="purple"
          v-if="getPstByid != undefined"
          v-show="getPstByid.nomor_ujian"
        >
          <div>
            Selamat {{ getPstByid.nm_pst }} Anda Sudah Terdaftar No Ujian Anda :
            {{ getPstByid.nomor_ujian }}
          </div>
          <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>
          <v-row align="center">
            <v-col>
              <v-list-item href="https://cbt.unikarta.ac.id/" target="_blank"
                >Link CBT Unikarta</v-list-item
              >
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="shrink">
              <v-btn @click="download">Download Kartu Tes</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-alert
          outlined
          color="blue"
          v-if="getPstByid != undefined"
          v-show="getPstByid.kode_daftar_ulang"
        >
          <v-row align="center">
            <v-col class="grow">
              Selamat Anda Lulus Tes SMPB Kode Daftar Ulang Anda :
              {{ getPstByid.kode_daftar_ulang }}
            </v-col>
            <v-col
              class="shrink"
              v-if="getPstByid !== null"
              v-show="getPstByid.is_daftar_ulang === 'Y'"
            >
              <v-btn @click="pernyataan">Download Penyataan</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="Kartu Tes"
      :pdf-quality="2"
      pdf-format="a5"
      pdf-orientation="portrait"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-content-width="100%"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="tes"
    >
      <section slot="pdf-content">
        <section class="pdf-item">
          <kartuTest></kartuTest>
        </section>
      </section>
    </vue-html2pdf>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1200"
      filename="Kartu Tes"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="90%"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="pernyataan"
    >
      <section slot="pdf-content">
        <section class="pdf-item">
          <pernyataan></pernyataan>
        </section>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script lang="js">
import kartuTest  from "../components/KartuTest.vue";
import pernyataan  from "../components/Pernyataan.vue";
import VueHtml2pdf from 'vue-html2pdf'
import {mapGetters,mapActions} from "vuex";
export default {
  name: "dashboardUser",
  data: () => ({
    //
    alert: false,
  }),
  components: {
  VueHtml2pdf,
  kartuTest,
  pernyataan
  },
  computed: {
    ...mapGetters("auth", ["userInfo"]),
    ...mapGetters("jadwals", ["allJadwal"]),
    ...mapGetters("daftar", ["getPstByid"]),
      htmlToPdfOptions() {
      return {
        margin: 0,
        filename: "Kartu Tes.pdf",
        html2canvas: {
          scale: 2,
          useCORS: true,
        },
          image: {
          type: "jpeg",
          quality: 0.98,
        },
      }
      },
  },
  created() {
    this.loadJadwal();
    this.$store.dispatch("daftar/loadpst", this.userInfo.id);
  },
  mounted() {
    this.$store.dispatch("daftar/loadpst", this.userInfo.id);
  },
  methods: {
    ...mapActions("jadwals", ["loadJadwal"]),
    daftar(jadwal) {
      this.$store.dispatch("daftar/daftarPst", {
        id_pst: this.userInfo.id,
        id_gelombang: jadwal.id,
        tahun: jadwal.tahun_penerimaan,
        nm_pst: this.userInfo.first_name,
      }).then(() => {
        this.$store.dispatch("daftar/loadpst", this.userInfo.id);
      })
    },
    download() {
      //let pr=this.$refs.tes.innerHTML
      this.$refs.tes.generatePdf()
    },
    pernyataan() {
      //let pr=this.$refs.tes.innerHTML
      this.$refs.pernyataan.generatePdf()
    },
  },
};
</script>
