<template>
  <div class="pdf-content">
    <v-card class="mb-6 mt-5" v-if="getPstByid !== null">
      <table
        class="table table-bordered table-condensed"
        style="margin-bottom: 0px !important;"
      >
        <tbody>
          <tr>
            <td width="15%" class="text-center">
              <v-avatar size="70" class="mr-4" tile>
                <img src="~@/assets/unikarta.png" />
              </v-avatar>
            </td>
            <td
              width="75%"
              class="text-center"
              style="vertical-align: middle !important;"
            >
              <h4 class="mr-2">
                <strong
                  >KARTU TES <br />
                  SELEKSI PENERIMAAN MAHASISWA BARU<br />
                  UNIVERSITAS KUTAI KARTANEGARA</strong
                >
              </h4>
            </td>
            <td
              width="15%"
              class="text-center"
              style="vertical-align: middle !important;"
            ></td>
          </tr>
        </tbody>
      </table>
      <v-divider class="mt-4"></v-divider>
      <table
        class="table table-bordered table-condensed"
        style="margin-bottom: 0px !important;"
      >
        <tbody>
          <tr>
            <td width="20%" rowspan="8">
              <qr-code
                text="getPstByid.foto_upload"
                size="120"
                error-level="L"
                color="#f1c40f"
                bg-color="#3498db"
              ></qr-code>
            </td>
          </tr>
          <tr>
            <td></td>
            <td width="30%">
              Nomor Peserta
            </td>
            <td width="70%" colspan="2">
              <span>: </span> <span> {{ getPstByid.nomor_ujian }}</span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td width="30%">
              Pilihan 1
            </td>
            <td width="70%">
              <span>: </span>
              <span>
                {{ getParam(getPstByid.pilihan_1).nama_program_studi }}
              </span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td width="30%">
              Pilihan 2
            </td>
            <td width="70%">
              <span>: </span>
              <span>{{
                getParam(getPstByid.pilihan_2).nama_program_studi
              }}</span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td width="30%">
              Kelas
            </td>
            <td width="70%">
              <span>: </span>
              <span v-if="getPstByid.kelas === 'R'"> Reguler</span>
              <span v-if="getPstByid.kelas === 'K'"> Khusus</span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td width="30%">
              Nama Peserta
            </td>
            <td width="70%">
              <span>: </span> <span>{{ getPstByid.nm_pst }}</span>
            </td>
          </tr>
          <tr>
            <td></td>
            <td width="30%">
              Tempat Lahir/tgl Lahir
            </td>
            <td width="70%">
              <span>: </span>
              <span
                >{{ getPstByid.tempat_lahir }},{{
                  getPstByid.tanggal_lahir
                }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
      <table
        class="table table-bordered table-condensed"
        style="margin-bottom: 0px !important;"
      >
        <tbody>
          <tr>
            <td width="40%" style="font-size:11px">
              <p>
                <u>Catatan :</u><br />
                1. Karu ini harap dibawa pada saat pelaksanaan Tes CAT Dan Tes
                Wawancara <br />
                2. Hadir 15 menit sebelun tes dimulai<br />
                3. Pakaian kemeja putih polos & bawahan celana/rok warna hitam
                polos<br />
                4. Bawa alat tulis
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "kartutest",
  computed: {
    ...mapGetters("daftar", ["getPstByid"]),
    ...mapGetters("reg", ["prodi"])
  },
  methods: {
    getParam(value) {
      return this.prodi.find(t => t.kode_program_studi == value) || "";
    }
  }
};
</script>
<style lang="scss" scoped>
.pdf-content {
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 20px;
  padding-bottom: 0px;
  text-align: justify;
  font-size: 16px;
}
</style>
