<template>
  <div class="pdf-content">
    <v-card v-if="getPstByid !== null">
      <div style="text-align:center">
        <v-avatar size="70" class="mr-4" tile>
          <img src="~@/assets/unikarta.png" />
        </v-avatar>
      </div>
      <H2 style="text-align:center"><u>SURAT PERNYATAAN</u></H2>
      <p>Yang Bertanda Tangan Dibawah ini :</p>
      <table
        class="table table-bordered table-condensed"
        style="margin-bottom: 0px !important;"
      >
        <tbody>
          <tr>
            <td width="30%">
              Nama Peserta
            </td>
            <td>:</td>
            <td width="80%">
              <span>{{ getPstByid.nm_pst }}</span>
            </td>
          </tr>
          <tr>
            <td width="30%">
              Tempat Lahir
            </td>
            <td>:</td>
            <td width="80%">
              <span>{{ getPstByid.tempat_lahir }}</span>
            </td>
          </tr>
          <tr>
            <td width="30%">
              Tanggal Lahir
            </td>
            <td>:</td>
            <td width="80%">
              <span>{{ getPstByid.tanggal_lahir }}</span>
            </td>
          </tr>
          <tr>
            <td width="30%">
              Contak Person
            </td>
            <td>:</td>
            <td width="80%">
              <span>{{ getPstByid.no_telp }}</span>
            </td>
          </tr>
          <tr>
            <td width="30%">
              Alamat
            </td>
            <td>:</td>
            <td width="80%">
              <span>{{ getPstByid.alamat }}</span>
            </td>
          </tr>
          <tr>
            <td width="30%">
              Program Studi
            </td>
            <td>:</td>
            <td width="80%">
              <span>{{
                getParam(getPstByid.pilihan_1).nama_program_studi
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <p style="text-align:justify;">Dengan ini berjanji untuk:</p>
      <ul style="list-style-type:circle;">
        <b>
          <li style="text-align:justify;">
            Tunduk dan Patuh terhadap Peraturan dan Ketentuan yang berlaku
            selama menjadi Mahasiswa pada Universitas Kutai Kartanegara;
          </li>
          <li style="text-align:justify;">
            Berpartisifasi aktif dalam kegiatan yang dilaksanakan oleh Fakultas
            maupun Universitas;
          </li>
          <li style="text-align:justify;">
            Berusahan semaksimal mungkin untuk Menyelesaikan studi tepat pada
            waktunya.
          </li>
          <li style="text-align:justify;">
            Apabila saya tidak memenuhi sebagaimana ketiga poin diatas, maka
            saya bersedia untuk mendapatkan sangsi sesuai dengan ketentuan yang
            berlaku.
          </li>
        </b>
      </ul>
      <br />
      <p style="text-align:justify">
        Demikian surat pernyataan ini saya buat dan ditanda tangani diatas
        materai tanpa dipengaruhi oleh pihak manapun dan bertanggungjawab penuh
        atas pernyataan yang saya buat.
      </p>
      <div class="tanggal">
        <br />
        <b>Tenggarong, {{ getPstByid.tanggal_daftar_ulang }}</b>
      </div>
      <div class="tandatangan">
        <br />
        <b>Mengetahui, Oarng tua/wali</b><br />
        <br /><br /><br /><br /><br />
        {{ getPstByid.nm_ayah }}
        <hr />
      </div>
      <div class="tandatangan2">
        <br />
        <b>Yang Membuat Pernyataan,</b>
        <br /><br /><br /><br />
        {{ getPstByid.nm_pst }}
        <hr />
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "pernyataan",
  data: () => ({
    nama_program_studi: ""
  }),
  computed: {
    ...mapGetters("daftar", ["getPstByid"]),
    ...mapGetters("reg", ["prodi"])
  },
  methods: {
    getParam(value) {
      return this.prodi.find(t => t.kode_program_studi == value) || "";
    }
  }
};
</script>
<style lang="scss" scoped>
.pdf-content {
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 20px;
  padding-bottom: 0px;
  text-align: justify;
  font-size: 16px;
}
.tandatangan {
  text-align: center;
  width: 225px;
  float: left;
}
.tandatangan2 {
  text-align: center;
  margin-left: 545px;
}
.tanggal {
  float: right;
}
</style>
